<template>
  <a-card :bordered="false" class="card-area">
    <div class="search">
      <!-- 搜索区域 -->
      <div class="items" style="flex: none">
        <div class="one-item">
          <a-input
              class="input"
              v-model="queryParams.uid"
              placeholder="申请人ID"
              allowClear
          />
        </div>
        <div class="one-item">
          <a-select
              class="input"
              v-model="queryParams.status"
              placeholder="状态"
              @change="search(1)"
              allowClear
          >
            <a-select-option v-for="r in dict['backtrackStatus']" :key="r.value.toString()">{{ r.label }}
            </a-select-option>
          </a-select>
        </div>
        <div class="one-item">
          <a-input
              class="input"
              v-model="queryParams.companyName"
              placeholder="公司名称"
              allowClear
          />
        </div>
      </div>
      <div class="operator">
        <a-button type="primary" @click="search(1)">查询</a-button>
        <a-button style="margin-left: 8px" @click="reset">重置</a-button>
<!--        <a-button v-hasPermission="'qrcode:add'" type="primary" @click="add" style="margin-left:20px;" icon="plus">-->
<!--          新增-->
<!--        </a-button>-->
      </div>
    </div>
    <a-table
        ref="TableInfo"
        size="small"
        :bordered="true"
        :columns="columns"
        rowKey="id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="loading"
        @change="handleTableChange"
        :scroll="{ x: 1000 }"
    >
      <template slot="images" slot-scope="text, record">
        <div v-if="record.userInfo.avatar" class="img-list">
          <el-image
              style="width: 40px; height: 40px"
              :src="(record.userInfo.avatar || '') | fullImgPath"
              fit="contain"
              :preview-src-list="(record.userInfo.avatar || '').split(',')"
          />
        </div>
      </template>
      <template slot="status" slot-scope="text, record">
        <a-tag :color="record.status | dictName('backtrackStatus', 'color')">{{
            record.status | dictName("backtrackStatus")
          }}
        </a-tag>
      </template>
      <template slot="payStatus" slot-scope="text, record">
        <a-tag :color="record.payStatus | dictName('backtrackPayStatus', 'color')">{{
            record.payStatus | dictName("backtrackPayStatus")
          }}
        </a-tag>
      </template>
      <template slot="applyMode" slot-scope="text, record">
        <a-tag :color="record.applyMode | dictName('backtrackMode', 'color')">{{
            record.applyMode | dictName("backtrackMode")
          }}
        </a-tag>
      </template>
      <template slot="reportUrl" slot-scope="text, record">
        <a :href="record.reportUrl" target="_blank" style="text-decoration:underline">{{ record.reportUrl }}</a>
      </template>

      <template slot="operation" slot-scope="text, record">
        <a-dropdown>
          <a-menu slot="overlay">
            <a-menu-item key="modify" @click="modify($event, record)">
              <a-icon type="setting"/>
              修改
            </a-menu-item>
            <a-menu-divider/>
            <a-menu-item
                key="delete"
                style="color: red"
                @click="getDelete($event, record)"
            >
              <a-icon type="delete"/>
              删除
            </a-menu-item>
          </a-menu>
          <a-button>
            操作
            <a-icon type="down"/>
          </a-button>
        </a-dropdown>
      </template>
    </a-table>
    <modify-modal ref="modifyModal" @success="editSuccess"/>
  </a-card>
</template>
<script>
import {ListMixin} from "@/mixin/list-mixin";
import modifyModal from "./modifyBacktrackModal.vue";

export default {
  name: "backtrackList",
  mixins: [ListMixin],
  components: {modifyModal},
  data() {
    return {
      listGetUrl: "backtrack/apply/listByAdmin",
      listMethod: "get",
    };
  },
  computed: {
    columns() {
      this.dataSource = this.dataSource.map((item) => {
        try {
          item.media = JSON.parse(item.media) || [];
        } catch (error) {
          item.media = [];
        }
        return item;
      });
      let {sortedInfo, filteredInfo} = this;
      sortedInfo = sortedInfo || {};
      filteredInfo = filteredInfo || {};
      return [
        {
          title: "ID",
          width: 80,
          align: "center",
          dataIndex: "id",
        },
        {
          title: "头像",
          align: "center",
          width: 80,
          dataIndex: "userInfo.avatar",
          scopedSlots: {customRender: "images"},
        },
        {
          title: "申请人ID",
          align: "center",
          width: 100,
          dataIndex: "uid",
        },
        {
          title: "申请人",
          align: "center",
          width: 150,
          dataIndex: "userInfo.username",
        },
        {
          title: "公司名称",
          align: "center",
          width: 200,
          dataIndex: "companyName",
        },
        {
          title: "状态",
          align: "center",
          width: 80,
          dataIndex: "status",
          scopedSlots: {customRender: "status"},
        },
        {
          title: "类型",
          align: "center",
          width: 80,
          dataIndex: "applyMode",
          scopedSlots: {customRender: "applyMode"},
        },
        {
          title: "申请时间",
          align: "center",
          width: 150,
          dataIndex: "createTime",
        },
        {
          title: "支付状态",
          align: "center",
          width: 80,
          dataIndex: "payStatus",
          scopedSlots: {customRender: "payStatus"},
        },
        {
          title: "支付金额",
          align: "center",
          width: 80,
          dataIndex: "payAmount",
        },
        {
          title: "完成时间",
          align: "center",
          width: 150,
          dataIndex: "finishTime",
        },
        {
          title: "背调报告结果",
          align: "center",
          width: 300,
          dataIndex: "reportUrl",
          scopedSlots: {customRender: "reportUrl"},
        },
        {
          title: "备注说明",
          align: "center",
          width: 200,
          ellipsis: true,
          dataIndex: "remark"
        },
        {
          title: "操作",
          align: "center",
          dataIndex: "operation",
          fixed: "right",
          scopedSlots: {customRender: "operation"},
        },
        // {}
      ];
    },
  },

  mounted() {
    this.search();
  },
  methods: {
    editSuccess() {
      this.search();
    },
    modify(e, record) {
      this.$refs.modifyModal.showModal(record, 'update');
    },
    add() {
      this.$refs.modifyModal.showModal(null, 'new');
    },
    getDelete(e, record) {
      let that = this;
      that.$message.info("暂未实现");
      // this.$confirm({
      //   title: "删除确认",
      //   content: "您确定要删除微信群【" + record.id + "】吗？！",
      //   centered: true,
      //   onOk() {
      //     that.$postJson("qrCode/delete", [record.id]).then(() => {
      //       that.$message.success("删除成功");
      //       that.search();
      //     });
      //   },
      // });
    },
  },
};
</script>

<style lang="less" scoped>
.textClick {
  // color: #1890ff;
  cursor: pointer;
  text-decoration: underline;
}

.img-list {
  display: flex;
  justify-content: center;
}
</style>
